import React from 'react'
import {buildImageObj, getBookUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import '../styles/book-preview.css'


function BookPreview (props) {

  return (
        <>
        {props.mainImage && props.mainImage.asset && (
              <div className="book-preview-item">
                <a href={getBookUrl(props.publishedAt, props.slug.current)}>
                <img
                  src={imageUrlFor(buildImageObj(props.mainImage))
                    .width(600)
                    .auto('format')
                    .url()}
                  alt={props.mainImage.alt}
                  title={props.mainImage.alt}
                />
                </a>

              </div>
            )}
        </>

  )
}

export default BookPreview
import React from 'react'
import {buildImageObj, getBookUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import '../styles/typesetting-preview.css'
import { TransitionGroup,CSSTransition } from 'react-transition-group';

class TypesettingPreview extends React.Component {
  constructor(props) {
    super(props);

      let pl = [];
      for (var i = 0; i < this.props.spreads.length; i++) {

      var mt = imageUrlFor(buildImageObj(this.props.spreads[i]))
                .height(1000)
                .auto('format')
                .url()

        pl.push(mt);
      }

      this.state = {
        index: 0,
        picList: pl,
        sliding: false,
      };
    }

  onClickNext= () => {

    if(this.props.slideIndex === this.props.thisIndex){

      if (this.state.index + 1 === this.state.picList.length ){
        console.log('OK');
        this.setState({ 
            index: 0 
          })
      } else {
          this.setState({
              index: this.state.index + 1
          })
      }

    }

  }

  componentDidMount() {
    this.state.picList.forEach((picture) => {
        const img = new Image();
        img.src = picture.fileName;
    });

    var intervalId = setInterval(this.onClickNext.bind(this), 5000);
    this.setState({
      intervalId: intervalId
    })

}

componentDidUpdate(prevProps, prevState) {

  
}

componentWillUnmount() {
   // use intervalId from the state to clear the interval
   clearInterval(this.state.intervalId);
}

  render() {

    return (

      <div className="typesetting-preview-item">

      <a href={getBookUrl(this.props.publishedAt, this.props.slug.current)}>
      {this.props.coverSpread && this.props.coverSpread.asset && this.props.coverSpread.alt && (
            <>
            <div class="head"><h4>{this.props.title}</h4></div>
            <TransitionGroup component={null}>
            <CSSTransition classNames="fade" timeout={500} key={this.state.picList[this.state.index]}>
              <img src={this.state.picList[this.state.index]} key={this.state.picList[this.state.index]} alt={this.props.coverSpread.alt} />
            </CSSTransition>
            </TransitionGroup>
            </>
        )}
      </a>
    </div>
    )
  }
}

export default TypesettingPreview
import {Link} from 'gatsby'
import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../styles/typesetting-preview-list.css'
import TypesettingPreview from './typesetting-preview'

class TypesettingPreviewList extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        slideIndex: 0
      };
  }


  slidePrevious = (value) => {
    this.slider.slickPrev();
  }

  slideNext = (value) => {
    this.slider.slickNext();
  }

  render() {

    //console.log('slides');
    var settings = {
      dots: false,
      arrows: true,
      className: "center",
      centerMode: true,
      centerPadding: "60px",
      infinite: true,
      slidesToShow: 3,
      speed: 500,
      swipeToSlide: true,
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
      responsive: [
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 1,
              }
            },
          ]
    };



    return (

    <>
      <div className="wrapper">
          {this.props.title && this.props.browseMoreHref && <h3 className="preview"><Link to={this.props.browseMoreHref}>{this.props.title}</Link></h3>}
        </div>
      <div className="typesetting-preview-list">
        <div id="typesetting-preview-list">

            <Slider ref={slider => (this.slider = slider)} {...settings}>
            {this.props.nodes &&
              this.props.nodes.map((node, index) => (
                <TypesettingPreview 
                  {...node} 
                  thisIndex={index}
                  slideIndex={this.state.slideIndex}
                  slideNext = {this.slideNext}
                />
            ))}
            </Slider>
            <div className="prevnext">
              <button className="button prev" onClick={this.slidePrevious}>
              </button>
              <button className="button next" onClick={this.slideNext}>
              </button>
            </div>

        </div>
      </div>
    </>
      
    )
  }
}

export default TypesettingPreviewList
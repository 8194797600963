import {Link} from 'gatsby'
import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../styles/project-preview-list.css'
import ProjectPreview from './project-preview'

var settings = {
  arrows: false,
  infinite: true,
  speed: 700,
  slidesToScroll: 1,
  swipeToSlide: true,
  dots: true,
};

function ProjectPreviewList (props) {
  return (
    <div className="project-preview-list">
      <div className="wrapper">
        {props.title && props.browseMoreHref && <h3 className="preview"><Link to={props.browseMoreHref}>{props.title}</Link></h3>}
      </div>
      <div id="project-preview-list">

          <Slider {...settings}>
          {props.nodes &&
            props.nodes.map(node => (
                  <ProjectPreview {...node} />
          ))}
          </Slider>

      </div>
    </div>
  )
}

ProjectPreviewList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default ProjectPreviewList
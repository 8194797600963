import React from 'react'
import {buildImageObj, getStudentWorkUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import '../styles/student-work-preview.css'


function StudentWorkPreview (props) {

  //console.log(props);

  return (
    <div className="student-work-preview-item">
      {props.images && props.images[0].asset && (
            <a title={props.images[0].alt} href={getStudentWorkUrl(props.year, props.slug.current)}>
            <img
              src={imageUrlFor(buildImageObj(props.images[0]))
                .width(800)
                .auto('format')
                .url()}
              alt={props.images[0].alt}
              title={props.images[0].alt}
            />
            </a>
        )}
    </div>
  )
}

export default StudentWorkPreview
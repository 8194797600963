import {Link} from 'gatsby'
import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../styles/book-preview-list.css'
import BookPreview from './book-preview'

class BookPreviewList extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        slideIndex: 0
      };
  }

  slidePrevious = (value) => {
    this.slider.slickPrev();
  }

  slideNext = (value) => {
    this.slider.slickNext();
  }

  render() {

    //console.log('slides');
    var settings = {
    dots: false,
    arrows: false,
    className: "center",
    centerMode: true,
    centerPadding: "20px",
    infinite: true,
    slidesToShow: 5,
    speed: 700,
    swipeToSlide: true,
    responsive: [
          {
            breakpoint: 700,
            settings: {
              centerPadding: "100px",
              slidesToShow: 1,
            }
          },
        ]
  };

    return (

    <>
      <div className="wrapper">
        {this.props.title && this.props.browseMoreHref && <h3 className="preview"><Link to={this.props.browseMoreHref}>{this.props.title}</Link></h3>}
      </div>
    <div className="book-preview-list">
      <div id="book-preview-list">

          <Slider ref={slider => (this.slider = slider)} {...settings}>
          {this.props.nodes &&
            this.props.nodes.map(node => (
              <BookPreview {...node} />
          ))}
          </Slider>
          <div style={{ textAlign: "center" }}>
            <button className="button prev" onClick={this.slidePrevious}>
            </button>
            <button className="button next" onClick={this.slideNext}>
            </button>
          </div>
      </div>
    </div>
    </>
      
    )
  }
}

export default BookPreviewList













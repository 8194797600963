import {Link} from 'gatsby'
import React from 'react'
import '../styles/student-work-preview-list.css'
import StudentWorkPreview from './student-work-preview'

function StudentWorkPreviewList (props) {
  return (
    <div className="student-work-preview-list wrapper">
        {props.title && props.browseMoreHref && <h3 className="preview"><Link to={props.browseMoreHref}>{props.title}</Link></h3>}
      <div id="student-work-preview-list">
        <div class="inner">
          {props.nodes &&
            props.nodes.map(node => (
                  <StudentWorkPreview {...node} />
          ))}
        </div>
      </div>
    </div>
  )
}

StudentWorkPreviewList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default StudentWorkPreviewList